import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'
import { requireGarmentType } from '../../helpers'
import { type MaterialTypes } from '../../types'
import { factoryDataSchema } from './fabric'

//* ---------------- BASE ---------------- *//
const baseMaterialSchema = z.object({
  id: z.number(),
  description: z.string(),
  image: z.string().url().optional(),
})

const liningMaterialSchema = baseMaterialSchema.extend({
  trinity_number: z.string(),
})

const fabricMaterialSchema = liningMaterialSchema.extend({
  collection: z.string(),
  swatch: z.string().url(),
  position: z.number(),
  stock_status: z.object({
    iD: factoryDataSchema.optional(),
    T2iD: factoryDataSchema.optional(),
  }),
})

const baseRackItemSchema = z.object({
  id: z.number(),
  description: z.string(),
  image: z.string().url().optional(),
})

const baseRackFabricSchema = baseRackItemSchema.extend({
  trinity_number: z.string(),
})

const baseRackFabricExtendedSchema = baseRackFabricSchema.extend({
  position: z.number(),
  stock_status: z.object({
    iD: factoryDataSchema.optional(),
    T2iD: factoryDataSchema.optional(),
  }),
})

const materialTypes: z.ZodType<MaterialTypes> = z.enum(['fabric', 'lining', 'button', 'thread', 'zipper', 'ribknit'])

//* ---------------- SCHEMAS ---------------- *//
export const fabricImageSchema = z
  .object({
    id: z.number(),
    image: z.string().url(),
    position: z.number(),
    garment_type: z.number(),
    base_model: baseRackItemSchema,
    fabric: baseRackFabricExtendedSchema.omit({ position: true }).nullable(),
    lining: baseRackFabricSchema.nullable(),
    button: baseRackItemSchema.nullable(),
    thread: baseRackItemSchema.nullable(),
    zipper: baseRackItemSchema.nullable(),
    ribknit: baseRackItemSchema.nullable(),
  })
  .transform(objectToCamel)
  .transform(({ garmentType, ...rest }) => ({ garmentType: requireGarmentType(garmentType), ...rest }))

export const garmentRenderSchema = z
  .object({
    id: z.number(),
    title: z.string(),
    order_id: z.number(),
    garment_type: z.number(),
    created_at: z.coerce.date(),
    trinity_fabric_number: z.string(),
    fabric_description: z.string(),
    uri: z.string().url().nullable(),
    is_hidden: z.boolean(),
    collection: z.string(),
    lining: z.string().nullable(),
    button: z.string().nullable(),
  })
  .transform(objectToCamel)
  .transform(({ garmentType, ...rest }) => ({ garmentType: requireGarmentType(garmentType), ...rest }))

export const visualizerFabricSchema = z
  .object({
    relevant_materials: z.array(materialTypes),
    rendered_image: z.string().url(),
    garment_type: z.number(),
    base_model: z.number(),
    description: z.string(),
    fabric: fabricMaterialSchema.omit({ stock_status: true }).nullable(),
    lining: liningMaterialSchema.nullable(),
    button: baseMaterialSchema.nullable(),
    thread: baseMaterialSchema.nullable(),
    zipper: baseMaterialSchema.nullable(),
    ribknit: baseMaterialSchema.nullable(),
  })
  .transform(objectToCamel)
  .transform(({ garmentType, ...rest }) => ({ garmentType: requireGarmentType(garmentType), ...rest }))

export const visualizerRackSchema = z
  .object({
    id: z.number(),
    created_at: z.coerce.date(),
    updated_at: z.coerce.date(),
    garment_type: z.number(),
    image: z.string().url(),
    base_model: baseRackItemSchema.omit({ image: true }),
    fabric: baseRackFabricExtendedSchema,
    lining: baseRackFabricSchema.nullable(),
    button: baseRackItemSchema.nullable(),
    thread: baseRackItemSchema.nullable(),
    ribknit: baseRackItemSchema.nullable(),
    zipper: baseRackItemSchema.nullable(),
  })
  .transform(objectToCamel)

export const visualizerStylepageSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    uuid: z.string(),
    updated_at: z.coerce.date(),
    created_at: z.coerce.date(),
    items: z.array(visualizerRackSchema),
  })
  .transform(objectToCamel)
  .nullable()

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type FabricImageType = z.infer<typeof fabricImageSchema>
    type GarmentRenderType = z.infer<typeof garmentRenderSchema>
    type VisualizerFabricType = z.infer<typeof visualizerFabricSchema>
    type VisuzlierRackType = z.infer<typeof visualizerRackSchema>
    type VisualizerStylepageType = z.infer<typeof visualizerStylepageSchema>
  }
}
