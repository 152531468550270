import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { API, handleError } from './api'
import { baseModelSchema, customModelSchema } from './schemas'
import { FACTORYT2ID } from '../constants'

//* ---------------- APIS ---------------- *//
export async function getModels(rawParams: Record<string, unknown>) {
  try {
    const params = getModelsParamsSchema.parse(rawParams)
    const response = await API.get('base_models?active=true', { params })
    const models = z.array(baseModelSchema).parse(response.data)

    return models
  } catch (error) {
    return handleError(error, 'Models')
  }
}

export async function getCustomModels(rawParams?: Record<string, unknown>) {
  try {
    const params = getCustomModelsParamsSchema.parse({ ...rawParams })
    const response = await API.get('custom_models', { params })
    const models = z.array(customModelSchema).parse(response.data)

    return models
  } catch (error) {
    return handleError(error, 'Custom Models')
  }
}

//* ---------------- PARAMS ---------------- *//
const getModelsParamsSchema = z
  .object({
    garmentType: z.coerce.number(),
    tailoringGrade: z.coerce.number().optional(),
    orderType: z.coerce.number().optional(),
  })
  .transform(objectToSnake)

const getCustomModelsParamsSchema = z
  .object({
    garmentType: z.coerce.number().optional(),
    baseModelId: z.coerce.number().optional(),
    manufacturerId: z.coerce.number().default(FACTORYT2ID.id),
  })
  .transform(objectToSnake)

//* ---------------- HELPERS ---------------- *//
