import { AxiosError } from 'axios'
import { API, handleError } from './api'
import { visualizerStylepageSchema } from './schemas'

export async function getPublicStylepage(uuid: string) {
  try {
    const response = await API.get(`visualizer_stylepages/shared/${uuid}`)
    const stylepage = visualizerStylepageSchema.parse(response.data)

    return { error: false, data: stylepage, message: null } as const
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 409) {
      return { error: true, data: null, message: 'Stylepage not found.' } as const
    }
    return handleError(error, 'Stylepage')
  }
}
