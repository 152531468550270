import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'
import type { ALL_GARMENTS } from '../../constants'

//* ---------------- BASE ---------------- *//
const baseGarmentSchema = z.object({
  id: z.number(),
  title: z.string(),
  order_id: z.number(),
  copied_garment_id: z.number().nullable(),
  price: z.string(),
  option_cost: z.string(),
  garment_type: z.string(),
  created_at: z.coerce.date(),
  updated_at: z.coerce.date().nullable(),
})

export const baseGarmentHomeSchema = baseGarmentSchema.extend({
  status_updated_at: z.coerce.date(),
})

//* ---------------- SCHEMAS ---------------- *//
export const garmentSchema = baseGarmentSchema
  .extend({
    order_status_id: z.number(),
    delay_status_id: z.number(),
    fabric_url: z.string().url().nullable(),
    shipping_class: z.union([z.literal('direct'), z.literal('bulk')]),
  })
  .transform(objectToCamel)

export const garmentHomeSchema = baseGarmentHomeSchema.transform(objectToCamel)

export const baseModelSchema = z
  .object({
    id: z.number(),
    model: z.string(),
    description: z.string(),
    image: z.string(),
    garment_type: z.number(),
    tailoring_grade: z.number(),
    order_type: z.number(),
    active: z.boolean(),
    has_image: z.boolean(),
    display_order: z.number(),
  })
  .transform(objectToCamel)

export const customModelSchema = z
  .object({
    id: z.number(),
    model_desc: z.string(),
    active: z.boolean(),
    is_parent: z.boolean(),
    blocks: z.array(z.object({ id: z.number(), description: z.string() })),
    base_model: baseModelSchema,
  })
  .transform(({ model_desc, ...data }) => ({ description: model_desc, ...data }))
  .transform(objectToCamel)

export const garmentTypeSchema = z.number()

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type GarmentType = z.infer<typeof garmentSchema>
    type GarmentHomeType = z.infer<typeof garmentHomeSchema>
    type BaseModelType = z.infer<typeof baseModelSchema>
    type GarmentTypeType = z.infer<typeof garmentTypeSchema>
    type GarmentTypesType = (typeof ALL_GARMENTS)[number]
    type CustomModelType = z.infer<typeof customModelSchema>
  }
}

//* ---------------- HELPERS ---------------- *//
